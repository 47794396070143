
import RoleService from '@/services/RoleService';
import LocalStorageService from '@/services/LocalStorageService';
import { mapActions, mapGetters } from 'vuex';
import ItemService from '../services/ItemService';
import { defineComponent } from 'vue';

interface ComponentData {
  activateNotification: boolean;
  typeNotification: 'success' | 'error';
  titleNotification: string;
  messageNotification: string;
  isPopupBlock: string;
  showOrderlist: boolean;
  loading: boolean;
  isDeletePopupBlock: boolean;
  videos: any[];
  videoLength: number;
  videoItem: any;
  itemService: ItemService;
  popupInfo: any;
  localStorageService: LocalStorageService;
  userName: string;
  roleService: RoleService;
  alertTitle: string;
  alertMessage: string;
  showConfirm: boolean;
  errorMssage: string;
}

export default defineComponent({
  name: 'Videolist',
  data() {
    const data: ComponentData = {
      activateNotification: false,
      typeNotification: 'success',
      titleNotification: '',
      messageNotification: '',
      isPopupBlock: 'none',
      showOrderlist: false,
      loading: false,
      isDeletePopupBlock: false,
      videos: [],
      videoLength: 0,
      videoItem: null,
      itemService: undefined,
      popupInfo: null,
      localStorageService: undefined,
      userName: undefined,
      roleService: undefined,
      alertTitle: '',
      alertMessage: '',
      showConfirm: false,
      errorMssage: '',
    };
    return data;
  },
  created() {
    this.itemService = new ItemService();
    this.localStorageService = new LocalStorageService();
    this.userName = this.localStorageService.getItem('user-name');
    this.roleService = new RoleService(this.localStorageService);
    this.roleService.getRole();
  },
  mounted() {
    this.loading = true;
    this.videos = [];
    const value = this.roleService.isSuperadmin ? 0 : 1;
    this.$store.dispatch('getVideoList', value).then(() => {
      const temp = [];

      while (this.$store.getters.allVideoList.length) {
        const singleVideo = this.$store.getters.allVideoList.pop();
        if (singleVideo.orderId == 0) {
          temp.push(singleVideo);
        } else {
          this.videos.push(singleVideo);
        }
      }
      this.videos.sort((a: any, b: any) => {
        return a.orderId - b.orderId;
      });

      for (let i = 0; i < this.videos.length; i++) {
        this.videos[i].orderId = i + 1;
      }
      this.videos = [...this.videos, ...temp];
      this.videoLength = this.videos.length;
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(['getVideoList']),

    showNotifications(data: any) {
      this.activateNotification = data.activate;
      this.typeNotification = data.type;
      this.titleNotification = data.title;
      this.messageNotification = data.message;
    },

    handleOrderSubmit() {
      const validation: any = [];
      const errorIds = [];
      let errortype = 0;
      const reqestObj = [];

      for (let i = 0; i < this.videos.length; i++) {
        reqestObj.push({
          id: this.videos[i].id,
          title: this.videos[i].title,
          description: this.videos[i].description,
          isActive: this.videos[i].isActive,
          orderId: this.videos[i].orderId,
        });

        if (this.videos[i].orderId != 0) {
          if (!validation.includes(this.videos[i].orderId)) {
            validation.push(this.videos[i].orderId);
          } else {
            errorIds.push(this.videos[i].orderId);
            errortype = 1;
          }
        } else {
          errortype = 2;
        }
      }
      if (errortype == 1) {
        this.errorMssage =
          'Following are duplicate order number(s) ' + errorIds.join(', ');
        return;
      } else if (errortype == 2) {
        this.errorMssage = 'Please select order';
        return;
      }
      this.loading = true;
      this.submitHandler(reqestObj);
    },

    async submitHandler(items: any) {
      const item = items.pop();
      if (item) {
        await this.itemService.updateVideoInDb(item, this, 1);
        this.submitHandler(items);
      } else {
        this.loading = false;
        this.activateNotification = true;
        this.typeNotification = 'success';
        this.titleNotification = 'Success';
        this.messageNotification = 'Video order successfully!';
        this.reloadPage();
      }
    },

    reloadPage() {
      this.loading = true;
      const value = this.roleService.isSuperadmin ? 0 : 1;
      this.$store.dispatch('getVideoList', value).then(() => {
        const temp = [];
        this.videos = [];
        while (this.$store.getters.allVideoList.length) {
          const singleVideo = this.$store.getters.allVideoList.pop();
          if (singleVideo.orderId == 0) {
            temp.push(singleVideo);
          } else {
            this.videos.push(singleVideo);
          }
        }

        this.videos.sort((a: any, b: any) => {
          return a.orderId - b.orderId;
        });

        for (let i = 0; i < this.videos.length; i++) {
          this.videos[i].orderId = i + 1;
        }
        this.videos = [...this.videos, ...temp];

        this.videoLength = this.videos.length;
        this.loading = false;
        this.showOrderlist = false;
      });
    },

    openVideo(video: any) {
      this.popupInfo = video;
      this.isPopupBlock = 'block';
    },

    closePopup() {
      const video: any = document.getElementById('myVideoPlayer');
      video.pause();
      video.currentTime = 0;
      this.isPopupBlock = 'none';
    },

    deleteVideo(item: any) {
      this.videoItem = item;
      this.isDeletePopupBlock = true;
    },

    async confirmationDeleteOk() {
      this.loading = true;
      this.isDeletePopupBlock = false;
      await this.itemService.deleteVideoInDb({ id: this.videoItem.id });
      const index = this.videos.findIndex(
        (x: any) => x.id === this.videoItem.id,
      );
      this.videos.splice(index, 1);
      this.loading = false;
      this.alertTitle = 'Success';
      this.alertMessage = 'Video deleted successfully!';
      this.showConfirm = true;
    },

    positiveButton() {
      this.showConfirm = false;
    },

    negativeButton() {
      this.showConfirm = false;
    },

    showOrderList() {
      this.showOrderlist = true;
    },

    showVideoList() {
      this.showOrderlist = false;
    },
  },
  computed: mapGetters(['allVideoList']),
});
